import * as React from "react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {
  container,
  subtitle,
  title1,
  message,
  primaryAlert,
} from "./Forgotpassword.module.scss";
import { Constants } from "../../@types/Constants";
import ErrorMessageAlert from "../../components/common/ErrorMessageAlert/ErrorMessageAlert";

export const ForgotContainer = (props: any) => {
  return <div className={container}>{props.children}</div>;
};
export const Subtitle = (props: any) => {
  return (
    <div id={props.id} className={subtitle}>
      {props.children}
    </div>
  );
};
export const Title1 = (props: any) => {
  return (
    <h1 id={props.id} className={title1}>
      {props.children}
    </h1>
  );
};

export const ErrorAlert = (props: any) => {
  const {
    errorMessage,
    showActivationBtn,
    handleResendActivationEmail,
    isResendingEmail,
    isNotFound,
  } = props;
  return isNotFound ? (
    <Alert className={primaryAlert}>{errorMessage}</Alert>
  ) : (
    <Alert id="error-message" variant={`danger`}>
      {errorMessage}
      {showActivationBtn && (
        <Button
          className="ms-2"
          onClick={handleResendActivationEmail}
          disabled={isResendingEmail}
        >
          {isResendingEmail ? `Please wait...` : `Activate Account`}
        </Button>
      )}
    </Alert>
  );
};
export const ErrorInfo = () => {
  return (
    <Alert variant={`danger`}>
      Our team needs to take a closer look at your account. Please visit{" "}
      <Alert.Link href={window.location.origin + "/contact"}>
        {window.location.origin}/contact
      </Alert.Link>{" "}
      to request activation. Thank you!
    </Alert>
  );
};

export const Message = (props: { message: string; id: string }) => {
  return (
    <p id={props.id} className={message}>
      {props.message}
    </p>
  );
};

export const TrackErrorMessage = (props: {
  message: string;
  field: string;
}) => {
  const trackMessage =
    props.message && typeof props.message !== undefined
      ? `${props.message} - ${props.field}`
      : "";
  return (
    <ErrorMessageAlert
      errorType={Constants.ERRORSTYPE.TRAVELPASS}
      errorSubType={Constants.ERRORSSUBTYPE.TRAVELPASS.SIGNIN}
      message={trackMessage}
    >
      {props.message}
    </ErrorMessageAlert>
  );
};
